function formHandler(fileInputWrapper) {
    $('form[data-toggle="validator"]').validator().on('submit', function (e) {
        if (e.isDefaultPrevented()) {
            console.log('invalid');
        } else {
            e.preventDefault();

            var data = new FormData();

            jQuery.each($(e.target).find('input[name]:not([type="file"])'), function(i, item) {
                data.append($(item).attr('name'), $(item).val());
            });

            jQuery.each($(e.target).find('textarea[name]'), function(i, item) {
                data.append($(item).attr('name'), $(item).val());
            });


            $.ajax({
                url: $(e.target).attr('action'),
                data: data,
                cache: false,
                contentType: false,
                processData: false,
                method: 'POST',
                type: 'POST', // For jQuery < 1.9
                success: function(data){
                    $('#thanksModal').modal('show');

                    $(e.target).closest('.modal').modal('hide');
                    resetForms(e.target);
                }
            });

            setTimeout(function () {

                // if ($(e.target).closest('.modal').attr('id') === 'getOfferModal') {
                //     $('#thanksModal').modal('show');
                // } else {
                //     $('#thanksForInstructionModal').modal('show');
                // }

                $('#thanksModal').modal('show');

                $(e.target).closest('.modal').modal('hide');
                resetForms(e.target);
            }, 300);

        }
    });

    // function metrikaHandler($form) {
    //     if ($($form).find('input[type="file"]').length) {
    //         if ($($form).find('textarea').length) {
    //             yaCounter46390773.reachGoal('Form1');
    //         } else {
    //             yaCounter46390773.reachGoal('Form2');
    //         }
    //
    //     } else if (!$($form).find('input[type="email"]').length) {
    //         yaCounter46390773.reachGoal('Form3')
    //     }
    //
    // }


    function resetForms($form) {
        $($form).find("input:not([type='hidden']), textarea").val("");

        $($form).find('input[type="checkbox"]').attr('checked', 'checked');
    }
}
