function scrollHandler() {
    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    function setClass(selector) {
        $(selector).each(function () {
            if ($(this).isInViewport()) {
                $(this).addClass('float-up');
            }
        })
    }

    var $selectors = [
        '.services-block .row .col-md-4',
        '.advantages-block .row .col-md-3',
        '.articles.grid .grid-item',
        '.docs_body .item',
        '.customs_body .col-md-4',
        '.we_will_help_with .item',
        '.delivery-block .col-md-3',
        '.advantages-block-grey .col-md-3',
    ];

    $(window).on('resize scroll', function() {

        $selectors.forEach(function (item) {
            setClass(item);
        });
    });
}