document.addEventListener("DOMContentLoaded", function() {
    if ($('#servicesOpener').length) {
        document.getElementById('servicesOpener').addEventListener('click', function () {
            var swapText = document.getElementById('servicesOpener').dataset.swapText;
            var currentText = document.getElementById('servicesOpener').innerHTML;
            document.getElementById('servicesOpener').innerHTML = swapText;
            document.getElementById('servicesOpener').dataset.swapText = currentText;
            document.querySelector('.services-container').classList.toggle('opened');
        });
    }
    if ($('.articles.grid').length) {
        $('.articles.grid').masonry({
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item',
            gutter: 30,
            percentPosition: true,
            fitWidth: true
        });
    }

    $('.menu-toggle').on('click', function (e) {
        e.preventDefault();
       $('body>header nav.menu').toggleClass('active');
    });

    if ($('.feedback-block').length) {
        $('.feedback-block .row.tabs .row.tab:first-child').addClass('active');
        $('.feedback-block .row.controls>div:first-child').addClass('active');
    }

    $(".feedback-block .controls>div").on('click', function () {
        var index = $(this).index();
        $(".feedback-block .controls>div").removeClass('active');
        $(this).addClass('active');
        $('.feedback-block .row.tabs .tab').eq($(this).index()).addClass('active').siblings().removeClass('active');
    });

    $('.feedback-block .arrows .prev').on('click', function () {
        var index = $('.feedback-block .row.tabs .tab.active').index();
        if (index > 0) {
            $('.feedback-block .arrows .next').removeClass('disabled');
            $('.feedback-block .row.tabs .tab').eq(index - 1).addClass('active').siblings().removeClass('active');
            $('.feedback-block .controls>div').eq(index - 1).addClass('active').siblings().removeClass('active');
            if (index < 1) {
                $(this).addClass('disabled');
            }
        }
    });

    $('.feedback-block .arrows .next').on('click', function () {
        var index = $('.feedback-block .row.tabs .tab.active').index();
        if (index < $('.feedback-block .row.tabs .tab').length - 1) {
            $('.feedback-block .arrows .prev').removeClass('disabled');
            $('.feedback-block .row.tabs .tab').eq(index + 1).addClass('active').siblings().removeClass('active');
            console.log(index);
            $('.feedback-block .controls>div').eq(index + 1).addClass('active').siblings().removeClass('active');
            if (index > $('.feedback-block .row.tabs .tab').length - 2) {
                $(this).addClass('disabled');
            }
        }
    });

    $(".phone-input").mask("+9 (999) 999-99-99");

    formHandler();

    $('.call-trigger').click(function (e) {
        e.preventDefault();
        $('#callbackModal').modal('show');
    });

    $('.order-trigger').click(function (e) {
        e.preventDefault();
        $('#orderModal').modal('show');
    });

    scrollHandler();
});

$(window).on("load", function(){
    $("section.head_blog .container_slick").slick({
        dots: false,
        arrows: true,
        draggable: false,
        //infinite: true,
        speed: 300,
        slidesToShow: 3,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
});
